(function($) {

  var $searchbar = $('#searchbar');

  // Main navigation Hamburger toggler
  $('#search-toggle').click(function(){
    console.log('klik');
    if ( $searchbar.hasClass('expanded') ) {
      console.log('inklappen');
      $searchbar.removeClass('expanded');
    } else {
      console.log('uitklappen');
      $searchbar.addClass('expanded');
    }
  });
})(jQuery);
